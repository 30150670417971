import React, { useState, useEffect } from "react"
import { fetchFromAPI } from "../../utility/general"
import success from "./../../images/success.png"
import error from "./../../images/error.png"
import plane from "./../../images/paper-plane.svg"
import "./Contact.scss"
function Contact() {
  const [name, setName] = useState("")
  const [company, setCompany] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [processing, setProcessing] = useState(false)
  const [state, setState] = useState("not-sent")

  const clearState = () => {
    setName("")
    setCompany("")
    setEmail("")
    setMessage("")
    setPhone("")
    window.scrollTo(0, 0)
  }
  const sendMessage = async () => {
    var token = ""
    setProcessing(true)
    const response = await fetchFromAPI(
      "POST",
      "api/reach-out",
      {
        body: {
          name: name,
          company: company,
          email: email,
          phone: phone,
          message: message,
        },
      },
      token
    )
    console.log({ response })
    setProcessing(false)
    if (response.success) {
      setState("success")
      clearState()
    }
    if (response.error) {
      setState("error")
      clearState()
    }
  }

  return (
    <>
      <hr
        id="contact"
        className="hr-branded"
        style={{ marginTop: "40px" }}
      ></hr>
      <div className="columns is-vcentered is-multiline">
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-10-tablet is-12-mobile">
          {/* <div className="notification is-warning">

            We got your message. Thank you!
          </div> */}
        </div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>

        {state === "success" ? (
          <div className="column is-8-desktop is-10-tablet is-12-mobile">
            <div className="">
              <div style={{ maxWidth: "300px", margin: "0 auto" }}>
                <img
                  src={success}
                  alt="congratulations"
                  style={{ width: "100%" }}
                />
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "26px",
                }}
              >
                We got your message!
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "74px",
                  marginTop: "26px",
                  fontWeight: "300",
                }}
              >
                Thank you!
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        {state === "error" ? (
          <div className="column is-8-desktop is-10-tablet is-12-mobile">
            <div className="">
              <div style={{ maxWidth: "300px", margin: "0 auto" }}>
                <img src={error} alt="error" style={{ width: "100%" }} />
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                There was a problem receiving your message, try again in
                sometime. If the issue persists, please reach out to{" "}
                <a href="mailto: support@voyayge.com">support@voyayge.com</a>
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "74px",
                  marginTop: "20px",
                  fontWeight: "300",
                }}
              >
                Sorry!
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        {state === "not-sent" ? (
          <>
            <div className="column is-3-desktop is-5-tablet is-12-mobile">
              <div
                style={{
                  // paddingBottom: "10px",
                  width: "250px",
                  margin: "0 auto",
                  marginBottom: "-70px",
                }}
              >
                <img src={plane} alt="" />
              </div>
              <p
                style={{
                  color: "#4a4a4a",
                }}
                className="section-header"
              >
                GET IN<br></br>TOUCH<br></br>WITH US
                <span
                  style={{
                    color: "#fec600",
                  }}
                >
                  .
                </span>
              </p>
              {/* <p
                className="contactus-text"
                // style={{
                //   fontSize: "4.688rem",
                //   fontWeight: "700",
                //   color: "#4a4a4a",
                //   lineHeight: "1.3",
                // }}
              >
                GET IN TOUCH WITH US
              </p> */}
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  marginTop: "15px",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    letterSpacing: "0.5px",
                  }}
                >
                  {" "}
                  <i
                    className="far fa-phone fa-lg"
                    style={{ marginRight: "3px", color: "#fec600" }}
                  ></i>{" "}
                  +1 725-600-0370
                </p>
              </p>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  marginTop: "15px",
                }}
              >
                <i
                  className="far fa-envelope fa-lg"
                  style={{ marginRight: "3px", color: "#fec600" }}
                ></i>{" "}
                <a
                  href="mailto: reach-out@voyayge.com"
                  style={{
                    textDecoration: "none",
                    color: "#4a4a4a",
                    letterSpacing: "0.5px",
                  }}
                >
                  reach-out@voyayge.com
                </a>
              </p>
            </div>
            <div className="column is-5-desktop is-5-tablet is-12-mobile">
              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={name}
                    onChange={e => setName(e.currentTarget.value)}
                  ></input>
                </div>
              </div>
              <div className="field">
                <label className="label">Company</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={company}
                    onChange={e => setCompany(e.currentTarget.value)}
                  ></input>
                </div>
              </div>
              <div className="field">
                <label className="label">Phone</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={phone}
                    onChange={e => setPhone(e.currentTarget.value)}
                  ></input>
                </div>
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={email}
                    onChange={e => setEmail(e.currentTarget.value)}
                  ></input>
                </div>
              </div>
              <div className="field">
                <label className="label">Message</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    placeholder=""
                    value={message}
                    onChange={e => setMessage(e.currentTarget.value)}
                  ></textarea>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <div style={{ maxWidth: "160px", marginLeft: "auto" }}>
                    <button
                      className={`${
                        processing
                          ? "button is-warning is-loading"
                          : "button is-warning"
                      }`}
                      style={{ width: "100%" }}
                      // type="submit"
                      onClick={sendMessage}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {/* <hr className="hr-branded"></hr> */}
        <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
      </div>
    </>
  )
}

export default Contact
