import React, { Fragment } from "react"
import Layout from "../components/layout"
import Contact from "../components/Contact/Contact"
import SEO from "../components/seo"

const ContactUsPage = () => {
  return (
    <Fragment>
      <Contact />
    </Fragment>
  )
}

export default () => (
  <Layout>
    <SEO pageTitle="Contact Us" description="Contact Us" />
    <ContactUsPage />
  </Layout>
)
